<template>
  <v-layout row class="FormStyle" align-baseline>
    <v-flex sm2 text-xs-left>
      <p class="H6-Primary-Left WeekdayLabel">{{ day.dayLabel }}</p>
    </v-flex>
    <v-flex sm2>
      <time-select
        :label="openTimeLabel"
        v-bind:value.sync="day.openTime"
        :rules="rulesOpenTime"
        autocomplete
        :disabled="day.isClosedAllDay || day.is24Hours"
        :type="control_Type"
        style="cursor: text;"
      ></time-select>
    </v-flex>
    <v-flex sm1 class="arrow">
      <v-icon>mdi-arrow-right</v-icon>
    </v-flex>
    <v-flex sm2 align-start>
      <time-select
        :label="closeTimeLabel"
        v-bind:value.sync="day.closeTime"
        :rules="requiredCloseTime"
        autocomplete
        :disabled="day.isClosedAllDay || day.is24Hours"
        :type="control_Type"
        style="cursor: text;"
      ></time-select>
      <div class="nextDayLabel">{{ nextDayLabel }}</div>
    </v-flex>
    <v-flex sm2 class="CheckboxStylegroup">
      <v-checkbox
        v-model="day.is24Hours"
        v-if="!day.showOperaton"
        label="24 Hour Service"
        @change="AllDayOperation"
        color="#0d73d8"
        class="CheckboxStyle"
      />
    </v-flex>
    <v-flex sm2 class="CheckboxStylegroup">
      <v-checkbox
        v-model="day.isClosedAllDay"
        v-if="!day.showOperaton"
        label="Closed All Day"
        @change="CloseAllDay"
        color="#0d73d8"
        class="CheckboxStyle"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import rules from '@/rules';
import timeSelect from '@/components/timeSelect';

export default {
  components: { timeSelect },
  props: {
    day: {
      default: {},
    },
  },
  data: () => ({
    control_Type: 'time',
    rulesOpenTime: [rules.required('Select an Open Time')],
    requiredCloseTime: [rules.required('Select a Close Time')],
    openTimeLabel: 'Opens',
    closeTimeLabel: 'Closes',
    nextDayLabel: '',
  }),
  mounted() {
    this.CloseAllDay(this.day.isClosedAllDay);
  },
  computed: {
    getCloseTime() {
      return this.day.closeTime;
    },
  },
  watch: {
    'day.openTime': function openTime() {
      this.setNextDayLabel();
    },
    'day.closeTime': function closeTime() {
      this.setNextDayLabel();
    },
  },
  methods: {
    CloseAllDay(value) {
      if (value) {
        if (this.day.is24Hours) this.day.is24Hours = false;
        this.control_Type = 'text';
        this.day.openTime = 'Closed';
        this.day.closeTime = 'Closed';
        this.openTimeLabel = '';
        this.closeTimeLabel = '';
      } else {
        this.control_Type = 'time';
        this.day.openTime = this.day.openTime === 'Closed' ? '00:00' : this.day.openTime;
        this.day.closeTime = this.day.closeTime === 'Closed' ? '00:00' : this.day.closeTime;
        this.openTimeLabel = 'Opens';
        this.closeTimeLabel = 'Closes';
      }
    },
    AllDayOperation(value) {
      this.control_Type = 'time';
      if (value) {
        if (this.day.isClosedAllDay) this.day.isClosedAllDay = false;
        this.day.openTime = '00:00';
        this.day.closeTime = '00:00';
      } else {
        this.day.openTime = this.day.openTime;
        this.day.closeTime = this.day.closeTime;
      }
      this.closeTimeLabel = 'Closes (Next Day)';
      this.nextDayLabel = 'Next Day';
    },
    setNextDayLabel() {
      this.day.duration = this.getDuration(this.day);
      if (this.day.isCloseNextDay) {
        this.closeTimeLabel = 'Closes (Next Day)';
        this.nextDayLabel = 'Next Day';
        return;
      }
      this.closeTimeLabel = 'Closes';
      this.nextDayLabel = '';
    },
    getDuration(times) {
      if (!times.openTime || !times.closeTime || times.isClosedAllDay) {
        times.isCloseNextDay = false;
        return 0;
      }
      const today = DateTime.local();
      const startTimes = times.openTime.split(':');
      const closeTime = times.closeTime.split(':');

      let startDateTime = DateTime.local();
      let closeDateTime = DateTime.local();

      if (startTimes.length === 1) {
        startDateTime = today.set({ hour: startTimes[0], minutes: '0' });
      } else {
        startDateTime = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      if (closeTime.length === 1) {
        closeDateTime = today.set({ hour: closeTime[0], minutes: '0' });
      } else {
        closeDateTime = today.set({ hour: closeTime[0], minute: closeTime[1] });
      }
      let duration = closeDateTime.diff(startDateTime, 'hours');
      let hours_diff = duration.values.hours;

      if (hours_diff <= 0) {
        times.isCloseNextDay = true;
        const nextDaycloseDateTime = closeDateTime.plus({ days: 1 });
        duration = nextDaycloseDateTime.diff(startDateTime, 'hours');
        hours_diff = duration.values.hours;
      } else {
        times.isCloseNextDay = false;
      }

      return hours_diff;
    },
  },
};
</script>

<style scoped>
.FormStyle {
  min-width: min-content;
}

.Rectangle-3 {
  width: 150px;
  height: 56px;
  background-color: #ffffff;
}

.WeekdayLabel {
  margin-top: 20px;
  height: 30px;
  letter-spacing: 0.3px;
  color: #000000;
}

.arrow {
  padding-top: 12px;
}

.CheckboxStylegroup {
  margin-top: -5px;
}

.CheckboxStyle {
  margin-left: 30px;
  height: 30px;
}

.nextDayLabel {
  height: 5px;
  margin-top: -25px;
  padding-bottom: 40px;
  margin-left: -200px;
}
</style>
